import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion";
import { graphql, useStaticQuery } from "gatsby";

const LocationMap = () => {
  const {
    allContentfulDistributers: { nodes: locationData },
  } = useStaticQuery(MyDistributorQueriesDuplicate);
  return (
    <Accordion>
      {locationData.map((location, i) => {
        return (
          <AccordionItem key={location.contentful_id + i}>
            <AccordionItemHeading>
              <AccordionItemButton>{location.title}</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="contact-page map-container">
                <iframe
                  width="100%"
                  height="400"
                  frameBorder="0"
                  scrolling="no"
                  marginHeight="0"
                  marginWidth="0"
                  src={`https://maps.google.com/maps?q=${location.iframe_url.lat},${location.iframe_url.lon}&hl=es;z=14&output=embed `}
                ></iframe>
                <div className="address-data">
                  {location.address_title && (
                    <h5 className="text-dark">{location.address_title}</h5>
                  )}
                  {location.sub_field && (
                    <h6 className="text-secondary">{location.sub_field}</h6>
                  )}
                  {location.address_name && (
                    <h6 className="text-secondary">
                      <i className="bx bx-map mr-2"></i>
                      {location.address_name}
                    </h6>
                  )}
                  {location.sub_field2 && (
                    <h6 className="text-secondary">{location.sub_field2}</h6>
                  )}
                  {location.email && (
                    <h6 className="text-secondary">
                      <i className="bx bx-envelope mr-2"></i>
                      {location.email}
                    </h6>
                  )}
                  {location.phone && (
                    <h6 className="text-secondary">
                      <i className="bx bx-phone-call mr-2"></i>
                      {location.phone}
                    </h6>
                  )}
                  {location.phone2 && (
                    <h6 className="text-secondary">
                      <i className="bx bx-phone-call mr-2"></i>
                      {location.phone2}
                    </h6>
                  )}
                </div>
              </div>
            </AccordionItemPanel>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};

export const MyDistributorQueriesDuplicate = graphql`
  {
    allContentfulDistributers {
      nodes {
        address_name
        address_title
        email
        id
        iframe_url {
          lat
          lon
        }
        phone
        contentful_id
        spaceId
        title
        sub_field
      }
    }
  }
`;
export default LocationMap;
