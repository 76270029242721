import React from "react";
import { useIntl } from "gatsby-plugin-react-intl";
import { graphql, useStaticQuery } from "gatsby";
import NavbarTwo from "../components/App/NavbarTwo";
import Footer from "../components/App/Footer";
import Layout from "../components/App/Layout";
import ContactBanner from "../components/Contact/ContactBanner";
import ContactContent from "../components/Contact/ContactContent";

const About = () => {
  const {
    allContentfulMainSlider: { nodes: contactUs },
  } = useStaticQuery(query);
  const intl = useIntl();
  const contactUsImage =
    intl.locale === "en"
      ? contactUs[0].contactUsImage.fluid
      : contactUs[0].contactUsImageFr.fluid ||
        contactUs[0].contactUsImage.fluid;
  const contactUsImageFixed =
    intl.locale === "en"
      ? contactUs[0].contactUsImage.fixed.src
      : contactUs[0].contactUsImageFr.fixed.src ||
        contactUs[0].contactUsImage.fixed.src;
  const canonical = `${process.env.GATSBY_MAIN_URL}/contact`;

  return (
    <Layout
      title="Contact Us Page"
      canonical={canonical}
      image={contactUsImageFixed}
    >
      <NavbarTwo />
      <ContactBanner contactUsImage={contactUsImage} />
      <ContactContent />
      <Footer />
    </Layout>
  );
};

export const query = graphql`
  {
    allContentfulMainSlider {
      nodes {
        contactUsImage {
          fluid(quality: 100) {
            ...GatsbyContentfulFluid
          }
          fixed {
            src
          }
        }
        contactUsImageFr {
          fluid(quality: 100) {
            ...GatsbyContentfulFluid
          }
          fixed {
            src
          }
        }
      }
    }
  }
`;

export default About;
