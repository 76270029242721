import React from "react";

import LocationMap from "./LocationMap";

const ContactContent = (props) => {
  return (
    <section className="blog-details-area mt-5 ">
      <div className="container">
        <div className="row product-upper-container">
          <div className="container-fluid">
            <div className=" ptb-100 product_meta">
              <h4 className="d-block">Contact Us</h4>
              <LocationMap />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactContent;
