import React from "react";
import Image from "gatsby-image";



const ContactUsBanner = ({contactUsImage}) => {
 return (
    <div className="courses-details-area">
      <div className="courses-details-image">
        <Image fluid={contactUsImage} alt="Medart-contactUs Banner" />
      </div>
    </div>
  );
};


export default ContactUsBanner;
